<template>
  <div class="container-xl">

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">

        <div class="app-car app-card-chart shadow-sm" v-if="false">
          <div class="app-card-body d-flex justify-content-center align-items-center p-4" style="background-color:white;">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>

        <div class="app-card app-card-chart shadow-sm">
          <div class="app-card-body p-3">
            <div class="d-flex justify-content-between align-items-center">
                <h6> Importar marcas de vehiculos</h6>
                <input class="btn btn-primary" type="submit" value="Importar">
            </div>
          </div>
        </div>

        <div class="app-card app-card-chart shadow-sm mt-2">
          <div class="app-card-body p-3">
            <div class="d-flex justify-content-between align-items-center">
                <h6> Importar modelos de vehiculos</h6>
                <input class="btn btn-primary" type="submit" value="Importar">
            </div>
          </div>
        </div>
        

      </div>
    </div>

    <!-- Modal guardar -->
    <!-- <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Usuarios</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="usuario-nombre">Nombre:</label>
            <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuario.firstname">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-apellido">Apellido:</label>
            <input type="text" class="form-control" id="usuario-apellido" placeholder="Apellido" v-model="usuario.lastname">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-email">Email:</label>
            <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuario.email">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-password">Password:</label>
            <input type="text" class="form-control" id="usuario-password" placeholder="Password" v-model="usuario.password">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-rol">Rol:</label>
            <select class="form-control" id="usuario-rol" placeholder="Rol" v-model="usuario.rol_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{ rol.nombre }}</option>
            </select>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form> -->

  </div>
</template>

<script>
export default {
    mounted() {
        console.log('Component mounted.')
    }
}
</script>
