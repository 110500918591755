<template>
    <section class="mt-2">
        <h3>Roles <small><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rol in roles" :key="rol.id">
                                <td>{{ rol.nombre }}</td>
                                <td>{{ rol.descripcion }}</td>
                                <td>
                                    <a href="" @click.prevent="editar(rol)" title="Editar"><i class="mdi mdi-edit mdi-lg"></i></a>
                                    <a href="" @click.prevent="eliminar(rol)" title="Eliminar"><i class="mdi mdi-delete mdi-lg"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
            </div>
        </div>
        <form @submit.prevent="guardar">
            <modal v-model="modal">
                <div slot="header" class="modal-header">
                        <h4 class="modal-title">Roles</h4>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="form-control-label" for="rol-nombre">Nombre:</label>
                        <input type="text" class="form-control" id="rol-nombre" placeholder="Nombre" v-model="rol.nombre">
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="rol-descripcion">Descripción:</label>
                        <input type="text" class="form-control" id="rol-descripcion" placeholder="Descripción" v-model="rol.descripcion">
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="rol-permisos">Permisos:</label>
                        <template v-for="(permiso, id) in permisos">
                            <div class="form-check" :key="id">
                                <label>
                                    <input type="checkbox" :value="id" v-model="rol.permisos">
                                    <strong>{{ permiso.etiqueta }}</strong>
                                </label>
                            </div>
                            <template v-if="permiso.permisos">
                                <div class="form-check indent-20" v-for="(permiso, id) in permiso.permisos" :key="id">
                                    <label>
                                        <input type="checkbox" :value="id" v-model="rol.permisos">
                                        {{ permiso }}
                                    </label>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div slot="footer" class="modal-footer">
                    <button type="submit" class="btn btn-success">Guardar</button>
                    <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
                </div>
            </modal>
        </form>
    </section>
</template>

<script>
import axios from 'axios';

import { url } from '../../services/url_service';

export default {
    data() {
        return {
            modal: false,
            roles: [],
            permisos: [],
            rol: {
                permisos: [],
            },
            id: null,
            pagination: {
                currentPage: 1,
                pages: 1
            }
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.rol = {
                permisos: []
            };
            this.modal = true;
        },
        editar(rol) {
            this.id = rol.id;
            this.rol = {
                nombre: rol.nombre,
                descripcion: rol.descripcion,
                permisos: rol.permisos
            };
            this.modal = true;
        },
        async eliminar(rol) {
            let res = confirm(`Esta seguro de que desea borrar el rol ${rol.nombre} ${rol.apellido}`);
            if(res) {
                try {
                    await axios.delete(url.create(`/roles/${rol.id}`));
                    alert('Rol eliminado correctamente');
                    await this.listar();
                } catch(ex) {

                }
            }
        },
        async guardar() {
            try {
                let response = (this.id) ? await axios.put(url.create(`/roles/${this.id}`), this.rol) : await axios.post(url.create('/roles'), this.rol);
                await this.listar();
                this.modal = false;
            } catch (ex) {

            }
        },
        async listar() {
            try {
                let response = await axios.get(url.create('/roles'), { params: { page: this.pagination.currentPage } });
                this.roles = response.data.data;
                this.pagination.pages = Math.ceil(response.data.total / response.data.per_page);
            } catch(ex) {

            }
        },
        async listarPermisos() {
            try {
                let response = await axios.get(url.create('/roles/permisos'));
                this.permisos = response.data;
            } catch(ex) {

            }
        },
        cambiarPagina(page) {
            this.currentPage = page;
            this.listar();
        },
        async descargarXlsx() {
            let response = await axios.get(url.create('/roles/excel'), { params: {} });
            window.location.href = response.data.enlace;
        }
    },
    mounted() {
        this.listar();
        this.listarPermisos();
    }
}
</script>
