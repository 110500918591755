<template>
  <section>

    <div class="app-card app-card-chart h-100 shadow-sm">
      <div class="app-card-body p-3 pt-4 pb-4">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Servicios</h6>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">

        <div class="app-car app-card-chart shadow-sm" v-if="false">
          <div class="app-card-body d-flex justify-content-center align-items-center p-4" style="background-color:white;">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">

            <li class="nav-item">
              <button class="nav-link active" id="solicitados-tab" data-bs-toggle="tab" data-bs-target="#solicitados" type="button" role="tab" aria-controls="solicitados" aria-selected="true">
                Solicitudes Actuales</button>
            </li>

            <li class="nav-item">
              <button class="nav-link" id="activos-tab" data-bs-toggle="tab" data-bs-target="#activos" type="button" role="tab" aria-controls="activos" aria-selected="true">
                Servicios Activos</button>
            </li>

            <li class="nav-item">
              <button class="nav-link" id="inactivos-tab" data-bs-toggle="tab" data-bs-target="#inactivos" type="button" role="tab" aria-controls="inactivos" aria-selected="false">
                Servicios Finalizados</button>
            </li>
       
          </ul>


          <div class="tab-content" id="myTabContent">

            <!-- Solicitados -->
             <div class="tab-pane fade active show" id="solicitados" role="tabpanel" aria-labelledby="solicitados-tab">
                <div class="app-card app-card-chart h-100 shadow-sm">
                  <div class="app-card-body p-4">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Pasajero</th>
                            <th>Salida</th>
                            <th>Llegada</th>
                            <th>Precio</th>
                            <th>Tarifa</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="servicio in solicitudes" :key="servicio.id">
                            <td>{{ servicio.passenger_firstname +" "+servicio.passenger_lastname }}</td>
                            <td>{{ capitalize(servicio.address_from) }}</td>
                            <td>{{ capitalize(servicio.address_to) }}</td>
                            <td>{{ dinero(servicio.price) }}</td>
                            <td><b>{{ dinero(servicio.go_fare) }}</b></td>
                            <td>{{ fecha(servicio.created_at) }}</td>
                             <td>
                              <span class="badge bg-info ">{{changeStatus(servicio.status)}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
                  </div>
                </div>
            </div>


            <!-- Activos -->
            <div class="tab-pane fade" id="activos" role="tabpanel" aria-labelledby="activos-tab">
                <div class="app-card app-card-chart h-100 shadow-sm">
                  <div class="app-card-body p-4">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Pasajero</th>
                            <th>Conductor</th>
                            <th>Salida</th>
                            <th>Llegada</th>
                            <th>Precio</th>
                            <th>Tarifa</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="servicio in activos" :key="servicio.id">
                            <td>{{ servicio.passenger_firstname +" "+servicio.passenger_lastname }}</td>
                            <td>{{ servicio.driver_firstname +" "+servicio.driver_lastname }}</td>
                            <td>{{ capitalize(servicio.address_from) }}</td>
                            <td>{{ capitalize(servicio.address_to) }}</td>
                            <td>{{ dinero(servicio.price) }}</td>
                            <td><b>{{ dinero(servicio.go_fare) }}</b></td>
                            <td>{{ fecha(servicio.created_at) }}</td>
                            
                            <td>
                              <span v-if=" servicio.status == 'ARRIVED'" class="badge bg-success">{{changeStatus(servicio.status)}}</span>
                              <span v-else class="badge bg-secondary">{{changeStatus(servicio.status)}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
                  </div>
                </div>
            </div>

            <!-- Terminados -->
             <div class="tab-pane fade" id="inactivos" role="tabpanel" aria-labelledby="inactivos-tab">
                
                <div class="app-card app-card-chart h-100 shadow-sm">
                  <div class="app-card-body p-4">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Pasajero</th>
                            <th>Conductor</th>
                            <th>Salida</th>
                            <th>Llegada</th>
                            <th>Precio</th>
                            <th>Tarifa</th>
                            <th>Fecha</th>
                            <th>Calificaciones</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="servicio in inactivos" :key="servicio.id">
                            <td>{{ servicio.passenger_firstname +" "+servicio.passenger_lastname }}</td>
                            <td>{{ servicio.driver_firstname +" "+servicio.driver_lastname }}</td>
                            <td>{{ capitalize(servicio.address_from) }}</td>
                            <td>{{ capitalize(servicio.address_to) }}</td>
                            <td>{{ dinero(servicio.price) }}</td>
                            <td><b>{{ dinero(servicio.go_fare) }}</b></td>
                            <td>{{ fecha(servicio.created_at) }}</td>
                            <td>{{ "Conductor-"+ servicio.driver_rating+"\nCliente-"+ servicio.passenger_rating }}</td>

                            <td>
                              <span v-if=" servicio.status == 'CLOSED'" class="badge bg-success">{{changeStatus(servicio.status)}}</span>
                              <span v-else class="badge bg-warning">{{changeStatus(servicio.status)}}</span>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
                  </div>
                </div>

            </div>

          </div>

      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

import { url } from "../../services/url_service";


export default {
  data() {
    return {

      modalDatos: false,

      id: null,
      listaServicios: [],
      servicio: {},

      pagination: {
        currentPage: 1,
        pages: 1,
      },

    };
  },

  computed: {

    solicitudes: function() {
      return _.pickBy(this.listaServicios, function(u) {
        if( u.status == "REQUESTED" ) return u;
        ;
      });
    },

    activos: function() {
      return _.pickBy(this.listaServicios, function(u) {
        if( u.status != "REQUESTED" && u.status != "CLOSED" && u.status != "CANCELED_BY_PASSENGER" && u.status != "CANCELED_BY_DRIVER"  ) return u;
      });
    },

    inactivos: function() {
      return _.pickBy(this.listaServicios, function(u) {
        if( u.status == "CLOSED" || u.status == "CANCELED_BY_PASSENGER" || u.status == "CANCELED_BY_DRIVER" ) return u;
      });
    },

    

  },

  methods: {

    dinero( cantidad ){
       const formatter =new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })
      var dinero = formatter.format(cantidad);
      return dinero;

    },

    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },

    changeStatus( status ){

      var statusList = {
        "REQUESTED" : 'Solicitado',
        "RESPONDED" : 'Respuesta',
        "ACCEPTED_BY_PASSENGER" : 'Aceptado',
        "DRIVER_ON_SITE" : 'Recogida',
        "DEPARTED" : 'Iniciado',
        "ARRIVED" : 'Llegada',
        "CLOSED" : 'Terminado',
        "CANCELED_BY_PASSENGER" : 'C.Pasajero',
        "CANCELED_BY_DRIVER" : 'C.Conductor',
      }
      return statusList[status];
    },

    fecha( date ){
      var javaDate = new Date(date);
      Date.prototype.formatMMDDYYYY = function(){
          return (this.getMonth() + 1) + 
          "-" +  this.getDate() +
          "-" +  this.getFullYear();
      }
      return javaDate.formatMMDDYYYY();
    },


    async listar() {
      try {
        let response = await axios.get(url.create("/services/admin"), {
          params: { 
            page: this.pagination.currentPage,
            // status: "CLOSED",
          },
        });
        this.listaServicios = response.data.data;
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    cambiarPagina(page) {
      this.currentPage = page;
      this.listar();
    },
  },
  mounted() {
    this.listar();
  },
};
</script>
