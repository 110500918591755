/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'

import Vue from 'vue';
import VueRouter from 'vue-router'

// import { BootstrapVue } from 'bootstrap-vue'

import axios from 'axios'
import jqueryParam from 'jquery-param'

import { auth } from './services/auth_service'
import routes from './routes'

// Importaciones propias
import './components'
import './directives'



import Main from './controllers/MainController'

//Serializar los parametros de querystring usando jquery
axios.defaults.paramsSerializer = params => jqueryParam(params);

axios.interceptors.request.use(
    request => {
        request.withCredentials = true;
        return request;
    },
    error => Promise.reject(error)
);


Vue.use(VueRouter);
Vue.use({
    install(Vue) {
        Vue.prototype.$_ = _;
        Vue.prototype.$auth = auth;
    }
});

// Vue.use(BootstrapVue)

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (auth.verify()) {
            auth.data().then(() => {
                next();
            }).catch(() => {
                next({
                    name: 'login'
                });
            });
        } else {
            next({
                name: 'login'
            });
        }
    } else {
        next();
    }
});

axios.interceptors.response.use(
    response => {
        return new Promise((resolve, reject) => {
            if (response.status === 401) {
                auth.refresh().then(
                    () => {
                        axios(response.config).then(newResponse => {
                            resolve(newResponse);
                        }).catch(error => {
                            reject(error.response);
                        });
                    },
                    () => {
                        reject(response);
                    }
                );
            } else {
                resolve(response);
            }
        });
    },
    error => Promise.reject(error)
);



export default new Vue({
    el: '#root',
    render: createElement => createElement(Main),
    router
});
