<template>
  <section>


    <!-- Modal Información -->
    <modal v-model="modalDatos" size="extra">

      <div slot="header" class="modal-header">
        <h4 class="modal-title">Información Usuario</h4>
      </div>

      <div class="modal-body">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <button class="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">
              Información personal</button>
          </li>
          <!-- <li class="nav-item">
            <button class="nav-link" id="historial-viajes-tab" data-bs-toggle="tab" data-bs-target="#viajes" type="button" role="tab" aria-controls="viajes" aria-selected="false">
              Historial de servicios</button>
          </li> -->
          
          <li class="nav-item">
            <button class="nav-link" id="historial-pagos-tab" data-bs-toggle="tab" data-bs-target="#pagos" type="button" role="tab" aria-controls="pagos" aria-selected="false">
              Bolsa</button>
          </li>

          <!-- <li class="nav-item">
            <button class="nav-link" id="acciones-tab" data-bs-toggle="tab" data-bs-target="#acciones" type="button" role="tab" aria-controls="acciones" aria-selected="false">
              Mas Acciones</button>
          </li> -->
        </ul>


        <div class="tab-content" id="myTabContent">

          <!-- Info -->
          <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
              <div class="row align-items-center justify-content-center ">
                <div class="col border-end mt-2 mb-2">
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-nombre">Nombre</h6>
                    <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuario.firstname">
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-apellido">Apellido</h6>
                    <input type="text" class="form-control" id="usuario-apellido" placeholder="Apellido" v-model="usuario.lastname">
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-email">Email</h6>
                    <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuario.email"  aria-label="Disabled input example" disabled readonly>
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-password">Contraseña</h6>
                    <input type="text" class="form-control" id="usuario-password" placeholder="Nueva contraseña" v-model="usuario.password">
                  </div>
                </div>
                
                <div class="col">
                  <div class="row d-flex justify-content-center">
                  
                    <div class="row mt-2">
                      <div class="col"><h6>Documento de identidad</h6></div>
                      <div class="col row d-flex justify-content-center">
                        <button type="submit" class="btn btn-success" @click="obtenerDocumento('identity')" >Ver documento</button>
                        </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col"><h6>Licencia de conduccion</h6></div>
                      <div class="col row d-flex justify-content-center">
                        <button type="submit" class="btn btn-success" @click="obtenerDocumento('license')" >Ver documento</button>
                        </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col"><h6>Tarjeta de propiedad</h6></div>
                      <div class="col row d-flex justify-content-center">
                        <button type="submit" class="btn btn-success" @click="obtenerDocumento('property')" >Ver documento</button>
                        </div>
                    </div>

                  </div>
                </div>
                
              </div>

          </div>

          <!-- Viajes -->
          <!-- <div class="tab-pane fade" id="viajes" role="tabpanel" aria-labelledby="historial-viajes-tab">

            <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Dirección de salida</th>
                  <th>Dirección de llegada</th>
                  <th>Precio</th>
                  <th>Fecha</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="usuario in listaUsuarios" :key="usuario.id">
                  <td>
                      <a href="" @click.prevent="editar(usuario)" >{{ usuario.firstname +' '+ usuario.lastname }}</a>
                  </td>
                  <td>{{ usuario.email }}</td>
                  <td>{{ usuario.email }}</td>
                  <td>
                    <template v-if="usuario.is_driver">
                      <i :class="usuario.is_driver_verified ? 'text-success': 'text-danger'" class="fas fa-check"></i>
                    </template>
                  </td>
                  <td>{{ usuario.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
            
          </div> -->

          <!-- Pagos y saldo-->
          <div class="tab-pane fade" id="pagos" role="tabpanel" aria-labelledby="historial-pagos-tab">

            <div class="row mt-4 ml-3 d-flex align-items-center">
             
              <div class="col-2 d-flex justify-content-center">
                <h5>Saldo actual<h3>{{ dinero(usuario.credits) }}</h3></h5>
              </div>
              <div class="col-5 ml-3">
                <div class="form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="saldo">Saldo a agregar</h6>
                  <input type="number" class="form-control" id="saldo" placeholder="0.000" v-model="agregarSaldoCantidad">
                </div>
                <button type="submit" class="btn btn-success" @click.prevent="agregarSaldo()">Agregar Saldo</button>
              </div>

              <div class="col-5 ml-3">
                <div class="form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="saldo">Saldo a retirar</h6>
                  <input type="number" class="form-control" id="saldo" placeholder="0.000" v-model="retirarSaldoCantidad">
                </div>
                <button type="submit" class="btn btn-danger" @click.prevent="retirarSaldo()">Retirar Saldo</button>
              </div>
              
            </div> 

          </div>



          <!-- Acciones -->
          <!-- <div class="tab-pane fade" id="acciones" role="tabpanel" aria-labelledby="acciones-tab">
            <div class="row d-flex justify-content-center">
              <div class="row mt-4">
                <div class="col">
                  <h6 v-if="usuario.is_active">Desactivar usuario</h6>
                  <h6 v-else >Activar usuario</h6>
                </div>
                <div class="col row d-flex justify-content-center">
                  <button v-if="usuario.is_active" type="button" class="btn btn-danger" @click="modalDatos = false;activarSwitch( usuario )">Desactivar</button>
                  <button v-else type="button" class="btn btn-success" @click="modalDatos = false;activarSwitch(usuario )">Activar</button>
                </div>
              </div> 
              <div class="row mt-3 mb-2">
                <div class="col"><h6>Eliminar usuario permanentemente</h6></div>
                <div class="col row d-flex justify-content-center">
                  <button type="button" class="btn btn-danger" @click="modalDatos = false;eliminar( usuario )">Eliminar</button>
                </div>
              </div> 
            </div>
          </div> -->

        </div>

      </div>

      <div slot="footer" class="modal-footer">
        <button type="submit" class="btn btn-success" @click.prevent="guardar()">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="modalDatos = false">Salir</button>
      </div>

    </modal>


    <!-- Eliminar Conductor -->
    <form @submit.prevent="validar">
      <modal v-model="modalValidar" size="extra">

        <div slot="header" class="modal-header">
          <h4 class="modal-title">Validar conductor</h4>
        </div>

        <div class="modal-body">

         <div class="col border-end mt-2 mb-2">
            <h6 class="form-control-label mb-2" for="usuario-password">Realmente desea validar a este conductor ?</h6>
          </div>

        </div>

        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success" @click="validarAccion()" >Validar conductor</button>
          <button type="button" class="btn btn-secondary" @click="modalValidar = false">Salir</button>
        </div>

      </modal>
    </form>

    <!-- Eliminar Usuario -->
    <form @submit.prevent="eliminar">
      <modal v-model="modalEliminar" size="extra">

        <div slot="header" class="modal-header">
          <h4 class="modal-title">Eliminar este conductor</h4>
        </div>

        <div class="modal-body">
          <div class="col border-end mt-2 mb-2">
            <h6 class="form-control-label mb-2" for="usuario-password">Realmente desea eliminar a este conductor ?</h6>
          </div>
        </div>

        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-danger" @click="eliminarAccion()" >Eliminar conductor</button>
          <button type="button" class="btn btn-secondary" @click="modalEliminar = false">Salir</button>
        </div>

      </modal>
    </form>


    <!-- Activar desactivar Usuario -->
    <modal v-model="modalActivarSwitch" size="extra">

      <div slot="header" class="modal-header">
        <h4 class="modal-title" v-if="usuario.is_active">Desactivar conductor</h4>
        <h4 class="modal-title" v-else>Activar conductor</h4>
      </div>

      <div class="modal-body">
        <div class="col border-end mt-2 mb-2">
          <h6 class="form-control-label mb-2" for="usuario-password">Con esta acción va cambiar el estado del conductor dentro de la plataforma ?</h6>
        </div>
      </div>

      <div slot="footer" class="modal-footer">
        <button v-if="usuario.is_active" type="submit" class="btn btn-danger" @click="activarSwitchAccion( usuario.is_active )" >Desactivar conductor</button>
        <button v-else type="submit" class="btn btn-success" @click="activarSwitchAccion( usuario.is_active )" >Activar conductor</button>
        <button type="button" class="btn btn-secondary" @click="modalActivarSwitch = false">Salir</button>
      </div>

    </modal>



    <div class="app-card app-card-chart h-100 shadow-sm">
      <div class="app-card-body p-3 pt-4 pb-4">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Conductores</h6>
            <!-- <input class="btn btn-primary" type="submit" value="Nuevo Ususario" @click.prevent="agregar()" style="color:white;"> -->
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">
 
        <div class="app-card app-card-chart h-100 shadow-sm">
          <div class="app-card-body p-4">

            <div class="table-responsive mb-3">

              <div class="col">
                <div class="row d-flex justify-content-between">

                <div class="col form-group mt-2 mb-4">
                  <h6 class="form-control-label mb-2" for="nombre">Nombre</h6>
                  <input type="text" class="form-control" id="nombre" placeholder="Nombre" v-model="filtroNombre">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="apellido">Apellido</h6>
                  <input type="text" class="form-control" id="apellido" placeholder="Apellido" v-model="filtroApellido">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="pasajero">Es pasajero</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroPasajero">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="activo">Está activo</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroActivo">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="verificado">Está verificado</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroVerificado">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                
                <div class="col d-flex align-items-center justify-content-end">
                  <div>
                    <button type="submit" class="btn btn-success" @click.prevent="listar()">Buscar conductores</button>
                  </div>
                </div>

                </div>

              </div>

              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Conductor</th>
                    <th>Pasajero</th>
                    <th>Activo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usuario in listaUsuarios" :key="usuario.id">
                    <td>
                        <a href="" @click.prevent="editar(usuario)" >{{ usuario.firstname +' '+ usuario.lastname }}</a>
                    </td>
                    <!-- <td>{{ usuario.lastname }}</td> -->
                    <td>{{ usuario.email }}</td>
                    <td>
                      <template v-if="usuario.is_driver" class="d-flex justify-content-center">
                        <i v-if="!usuario.is_driver_verified" class="fas fa-hourglass-half text-danger"></i>
                        <i v-else class="fas fa-check text-success"></i>
                      </template>
                    </td>
                    <td>
                      <template v-if="usuario.is_passenger">
                        <i class="fas fa-check"></i>
                      </template>
                      <template v-else>
                        <i class="fas fa-window-minimize"></i>
                      </template>
                    </td>
                    <td>
                      <template v-if="usuario.is_active" class="d-flex justify-content-center">
                        <i class="fas fa-check"></i>
                      </template>
                    </td>

                    <td>
                      <template v-if="usuario.is_active && usuario.is_driver && !usuario.is_driver_verified">
                        <a href="" @click.prevent="validar(usuario)" title="Validar conductor"><span class="badge rounded-pill bg-primary">Validar</span></a>
                      </template>
                      <a href="" @click.prevent="editar(usuario)" title="Editar"><i class="fas fa-edit text-warning"></i></a>
                      <a href="" @click.prevent="eliminar(usuario)" title="Eliminar"><i class="far fa-trash-alt text-danger"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import { url } from "../../services/url_service";


export default {
  data() {
    return {

      modalDatos: false,
      modalNuevoUsuario: false,
      modalValidar:false,
      modalEliminar:false,
      modalActivarSwitch:false,

      filtroNombre:null,
      filtroApellido:null,
      filtroPasajero:"null",
      filtroActivo:"null",
      filtroVerificado:"null",

      listaUsuarios: [],
      usuario: {},

      id: null,
      agregarSaldoCantidad:null,
      retirarSaldoCantidad:null,
      pagination: {
        currentPage: 1,
        pages: 1,
      },

    };
  },
  methods: {


    dinero( cantidad ){
       const formatter =new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })
      var dinero = formatter.format(cantidad);
      return dinero;

    },
    
    validar( usuario ) {
      this.id = usuario.id;
      this.usuario = {
        id:usuario.id,
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        is_active: usuario.is_active
      };

      this.modalValidar = true;
    },

    eliminar( usuario ) {
      this.id = usuario.id;
      this.usuario = {
        id:usuario.id,
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        is_active: usuario.is_active
      };

      this.modalEliminar = true;
    },

    activarSwitch( usuario ) {
      this.id = usuario.id;
      this.usuario = {
        id:usuario.id,
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        is_active: usuario.is_active
      };

      this.modalActivarSwitch = true;
    },

    async editar( usuario) {

      this.id = usuario.id;
      this.agregarSaldoCantidad = null
      this.retirarSaldoCantidad = null
      this.usuario = {
        id:usuario.id,
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        is_driver: usuario.is_driver,
        is_active: usuario.is_active,
        credits: null
      };

      let response = await axios.get( url.create(`/users/${this.usuario.id}/credits`) );
      var creditos = response.data["credits"];
      console.log(creditos)

      this.usuario.credits = creditos;

      this.modalDatos = true;
    },

    async listar() {
      try {

   
        let response = await axios.get(url.create("/users"), {
          params: {
            page: this.pagination.currentPage,
            is_driver: true,
            is_driver_verified: this.filtroVerificado,
            is_passenger: this.filtroPasajero,
            is_active: this.filtroActivo,
            firstname:this.filtroNombre,
            lastname:this.filtroApellido,
           },
        });
        this.listaUsuarios = response.data.data;
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    async obtenerDocumento( doc ) {
       try {

        let base64 = await axios.get(url.create(`/users/${this.usuario.id}/document`), {
          params: { doc: doc },
          responseType: "arraybuffer" 
        }).then((response) => {

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', doc+'.jpg');
            document.body.appendChild(fileLink);
            fileLink.click();

        });


      } catch (ex) {
        alert("No se encontró documento");
      }
    },

    async eliminarAccion() {
      try {
        await axios.delete(url.create(`/users/${this.usuario.id}`),);
        alert("Usuario eliminado correctamente");
        await this.listar();
        this.modalEliminar = false;
      } catch (ex) {
        alert("Usuario no pudo ser eliminado");
      }
      
    },

     async agregarSaldo() {
      try {
        await axios.put(url.create(`/users/${this.usuario.id}/credits/${this.agregarSaldoCantidad}`));
        await this.listar();
        this.modalDatos = false;
      } catch (ex) {
        alert("No se pudo actualizar el saldo");
      }
    },

    async retirarSaldo() {
      try {
        await axios.delete(url.create(`/users/${this.usuario.id}/credits/${this.retirarSaldoCantidad}`));
        await this.listar();
        this.modalDatos = false;
      } catch (ex) {
        alert("No se pudo actualizar el saldo");
      }
    },

    async activarSwitchAccion( status ) {
      try {
        console.log(!status)
        await axios.get(url.create(`/users/${this.usuario.id}/switch`),{
          params: {
            status: !status,
           },
        });
        await this.listar();
        this.modalActivarSwitch = false;
      } catch (ex) {
        alert("No se pudo actualizar el estado");
      }
      
    },

    async validarAccion() {
      try {
        await axios.post(url.create(`/users/${this.usuario.id}/verify`));
        alert("Usuario validado correctamente");
        await this.listar();
        this.modalValidar = false;
      } catch (ex) {
        alert("Usuario no pudo ser validado");
      }
      
    },

    async guardar() {
      try {
        let response = this.id != null
          ? await axios.put(url.create(`/users/${this.id}`), this.usuario )
          : await axios.post(url.create("/users"), this.usuario);

        await this.listar();
        this.modalDatos = false;
        
      } catch (ex) {
        console.log(ex)  
      }
    },
    
    cambiarPagina(page) {
      this.currentPage = page;
      this.listar();
    },

  },

  mounted() {
    this.listar();
  },

};
</script>

<style lang="scss" >

.modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}

</style>
