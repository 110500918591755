<template>
  <section>

    <modal v-model="modalDatos" size="extra">
      <div slot="header" class="modal-header">
        <h4 class="modal-title">Información Usuario</h4>
      </div>

      <div class="modal-body">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <button class="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">
              Información personal</button>
          </li>
        </ul>


        <div class="tab-content" id="myTabContent">

          <!-- Info -->
          <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
              <div class="row align-items-center justify-content-center ">

                <div class="col border-end mt-2 mb-2">
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-nombre">Nombre</h6>
                    <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuario.firstname">
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-apellido">Apellido</h6>
                    <input type="text" class="form-control" id="usuario-apellido" placeholder="Apellido" v-model="usuario.lastname">
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-email">Email</h6>
                    <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuario.email"  aria-label="Disabled input example" disabled readonly>
                  </div>
                  <div class="form-group mt-2">
                    <h6 class="form-control-label mb-2" for="usuario-password">Contraseña</h6>
                    <input type="text" class="form-control" id="usuario-password" placeholder="Nueva contraseña" v-model="usuario.password">
                  </div>
                </div>
              </div>
          </div>

        </div>

      </div>

      <div slot="footer" class="modal-footer">
        <button type="submit" class="btn btn-success" @click.prevent="guardar()">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="modalDatos = false">Salir</button>
      </div>
    </modal>


    <div class="app-card app-card-chart h-100 shadow-sm">
      <div class="app-card-body p-3 pt-4 pb-4">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Clientes</h6>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">
  
        <div class="app-card app-card-chart h-100 shadow-sm">
          <div class="app-card-body p-4">
            <div class="table-responsive">

               <div class="col">
                <div class="row d-flex justify-content-between">

                <div class="col form-group mt-2 mb-4">
                  <h6 class="form-control-label mb-2" for="nombre">Nombre</h6>
                  <input type="text" class="form-control" id="nombre" placeholder="Nombre" v-model="filtroNombre">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="apellido">Apellido</h6>
                  <input type="text" class="form-control" id="apellido" placeholder="Apellido" v-model="filtroApellido">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="activo">Está activo</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroActivo">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                
                <div class="col d-flex align-items-center justify-content-end">
                  <div>
                    <button type="submit" class="btn btn-success" @click.prevent="listar()">Buscar conductores</button>
                  </div>
                </div>

                </div>

              </div>


              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Activo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usuario in listaUsuarios" :key="usuario.id">
                    <td>
                        <a href="" @click.prevent="editar(usuario)" >{{ usuario.firstname +' '+ usuario.lastname }}</a>
                    </td>
                    <td>{{ usuario.email }}</td>
                    <td>
                      <template v-if="usuario.is_active">
                        <i class="fas fa-check"></i>
                      </template>
                    </td>
                    <td>
                      <a href="" @click.prevent="editar(usuario)" title="Editar"><i class="fas fa-edit text-warning"></i></a>
                      <a href="" @click.prevent="eliminar(usuario)" title="Eliminar"><i class="far fa-trash-alt text-danger"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

import { url } from "../../services/url_service";


export default {
  data() {
    return {

      modalDatos: false,
      modalNuevoUsuario: false,

      filtroNombre:null,
      filtroApellido:null,
      filtroPasajero:"null",
      filtroActivo:"null",
      filtroVerificado:"null",

      roles: [],
      listaUsuarios: [],
      usuario: {},
      usuarioNuevo:{},

      id: null,
      pagination: {
        currentPage: 1,
        pages: 1,
      },

    };
  },
  methods: {

    // Crear nuevo usuario
    agregar() {
      this.id = null;
      this.usuarioNuevo={};
      this.modalNuevoUsuario = true;
    },

    // Editar un usuario
    editar(usuario) {
      this.id = usuario.id;
      this.usuario = {
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        is_driver: usuario.is_driver,
        // rol_id: usuario.rol_id,
      };
      this.modalDatos = true;
    },

    async listar() {
      try {
        let response = await axios.get(url.create("/users"), {
          params: { 
            page: this.pagination.currentPage,
            is_driver: false,
            is_passenger:true,
            is_active: this.filtroActivo,
            firstname:this.filtroNombre,
            lastname:this.filtroApellido,
          },
        });
        this.listaUsuarios = response.data.data;
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.log("No se listó")
        console.error(ex);
      }
    },

    async eliminar(usuario) {
      let res = confirm(
        `Esta seguro de que desea borrar el usuario ${usuario.email}`
      );
      if (res) {
        try {
          await axios.delete(url.create(`/users/${usuario.id}`));
          alert("Usuario eliminado correctamente");
          await this.listar();
        } catch (ex) {
          alert("Usuario no pudo ser eliminado");
        }
      }
    },

    async validar(usuario) {
      let res = confirm(
        `Esta seguro de que desea validar al usuario ${usuario.email}`
      );
      if (res) {
        try {
          await axios.post(url.create(`/users/${usuario.id}/verify`));
          alert("Usuario validado correctamente");
          await this.listar();
        } catch (ex) {
          alert("Usuario no pudo ser validado");
        }
      }
    },

    async guardar() {
      try {
        let response = this.id != null
          ? await axios.put(url.create(`/users/${this.id}`), this.usuario )
          : await axios.post(url.create("/users"), this.usuario);

        await this.listar();
        this.modalDatos = false;
        
      } catch (ex) {
        console.log(ex)  
      }
    },
    
  
    cambiarPagina(page) {
      this.currentPage = page;
      this.listar();
    },
   
  },

  mounted() {
    this.listar();
  },
};
</script>
