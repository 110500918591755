<template>
    <div class="modal" :class="modalClass" tabindex="-1" role="dialog ">
        <div class="modal-dialog modal-dialog-scrollable" :class="dialogClass">
            <div class="modal-content">
                <!--Header-->
                <slot v-if="hasHeaderSlot" name="header"></slot>
                <!--Container-->
                <slot></slot>
                <!--Footer-->
                <slot v-if="hasFooterSlot" name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import {Modal} from 'bootstrap'
export default {
    name: 'ModalComponent',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large'
        },
        backdrop: {
            default: 'static',
        },
        effect: {
            type: String,
            default: 'fade'
        }
    },
    data() {
        return {
            modal: null,
        }
    },
    computed: {
        dialogClass() {
            switch(this.size) {
                case 'extra': return 'modal-xl'
                case 'large': return 'modal-lg'
                case 'small': return 'modal-sm'
                case 'full': return 'modal-fullscreen'
            }
        },
        modalClass() {
            return this.effect;
        },
        hasHeaderSlot () {
            return !!this.$slots['header']
        },
        hasFooterSlot () {
            return !!this.$slots['footer']
        }
    },
    mounted() {
        this.modal = new Modal(this.$el, {backdrop: this.backdrop})

        this.value ? this.modal.show() : this.modal.hide();
        let self = this;
        this.$el.addEventListener('hidden.bs.modal', function (event) {
            if (self.value) {
                self.$emit('input', false);
            }
        });
    },
    watch: {
        value(value, oldValue) {
            value ? this.modal.show() : this.modal.hide();
        }
    }
}
</script>
