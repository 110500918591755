<template>
  <section>

    <div class="app-card app-card-chart h-100 shadow-sm">
      <div class="app-card-body p-3 pt-4 pb-4">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Transacciones</h6>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">

        <div class="app-car app-card-chart shadow-sm" v-if="false">
          <div class="app-card-body d-flex justify-content-center align-items-center p-4" style="background-color:white;">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">

            <li class="nav-item">
              <button class="nav-link active" id="activos-tab" data-bs-toggle="tab" data-bs-target="#activos" type="button" role="tab" aria-controls="activos" aria-selected="true">
                Transacciones Completadas</button>
            </li>

            <li class="nav-item">
              <button class="nav-link" id="inactivos-tab" data-bs-toggle="tab" data-bs-target="#inactivos" type="button" role="tab" aria-controls="inactivos" aria-selected="false">
                Transacciones en proceso</button>
            </li>
       
          </ul>


          <div class="tab-content" id="myTabContent">

            <!-- Activos -->
            <div class="tab-pane fade show active" id="activos" role="tabpanel" aria-labelledby="activos-tab">
                
                <div class="app-card app-card-chart h-100 shadow-sm">
                  <div class="app-card-body p-4">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Cantidad</th>
                            <th>Conductor</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="transa in completadas" :key="transa.id">
                            <td>
                              <span class="badge bg-info text-dark">{{ changeStatus(transa.type) }}</span>
                            </td>
                            <td> <b>{{  dinero(transa.amount) }}</b></td>
                            <td>{{ transa.firstname +" "+transa.lastname }}</td>
                            <td>{{ fecha(transa.created_at) }}</td>
                            <td>
                              <span v-if="transa.status == 'ACCEPTED'" class="badge bg-success text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'REJECTED'" class="badge bg-danger text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'PENDING'" class="badge bg-warning text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'OPEN'" class="badge bg-secondary text-dark">{{ changeStatus(transa.status) }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination v-model="pagination1.currentPage" :pages="pagination1.pages" @change="cambiarPagina1"></pagination>
                  </div>
                </div>

            </div>

            <!-- Inactivos -->
             <div class="tab-pane fade" id="inactivos" role="tabpanel" aria-labelledby="inactivos-tab">
                
                <div class="app-card app-card-chart h-100 shadow-sm">
                  <div class="app-card-body p-4">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Cantidad</th>
                            <th>Conductor</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="transa in proceso" :key="transa.id">
                            <td>
                              <span class="badge bg-info text-dark">{{ changeStatus(transa.type) }}</span>
                            </td>
                             <td> <b>{{  dinero(transa.amount) }}</b></td>
                            <td>{{ transa.firstname +" "+transa.lastname }}</td>
                            <td>{{ fecha(transa.created_at) }}</td>
                            <td>
                              <span v-if="transa.status == 'ACCEPTED'" class="badge bg-info text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'REJECTED'" class="badge bg-danger text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'PENDING'" class="badge bg-warning text-dark">{{ changeStatus(transa.status) }}</span>
                              <span v-if="transa.status == 'OPEN'" class="badge bg-secondary text-dark">{{ changeStatus(transa.status) }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination v-model="pagination2.currentPage" :pages="pagination2.pages" @change="cambiarPagina2"></pagination>
                  </div>
                </div>

            </div>

          </div>

      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

import { url } from "../../services/url_service";


export default {
  data() {
    return {

      modalDatos: false,

      id: null,
      listaCompletadas: [],
      listaProceso: [],
      servicio: {},

      pagination1: {
        currentPage: 1,
        pages: 1,
      },

      pagination2: {
        currentPage: 1,
        pages: 1,
      },

    };
  },

  computed: {

    completadas: function() {

      return _.pickBy(this.listaCompletadas, function(u) {
        return u.status == "ACCEPTED";
      });
    },

    proceso: function() {
      return _.pickBy(this.listaProceso, function(u) {
        return u.status != "ACCEPTED";
      });
    }

  },

  methods: {

     capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },

    changeStatus( status ){

      var statusList = {
        "ACCEPTED" : 'Aceptada',
        "OPEN" : 'Abierta',
        "PENDING" : 'Pendiente',
        "REJECTED" : 'Rechazada',

        "RECHARGE" : 'Recarga',
        "DISCHARGE" : 'Descarga',
        "WEB_RECHARGE" : 'Recarga Web',
        "WEB_DISCHARGE" : 'Descarga Web',
      }
      return statusList[status];
    },

    dinero( cantidad ){
       const formatter =new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })
      var dinero = formatter.format(cantidad);
      return dinero;

    },

    fecha( date ){
      var javaDate = new Date(date);
      Date.prototype.formatMMDDYYYY = function(){
          return (this.getMonth() + 1) + 
          "-" +  this.getDate() +
          "-" +  this.getFullYear();
      }
      return javaDate.formatMMDDYYYY();
    },

    async listarCompletadas() {
      try {

        var response = await axios.get(url.create("/transactions/admin"), {
          params: {page: this.pagination1.currentPage, active:true  },
        });
        this.listaCompletadas = response.data.data;
        this.pagination1.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    async listarProceso() {
      try {

        var response = await axios.get(url.create("/transactions/admin"), {
          params: {page: this.pagination2.currentPage,},
        });
        this.listaProceso = response.data.data;
        this.pagination2.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    cambiarPagina1(page){this.pagination1.currentPage = page;this.listarCompletadas();},
    cambiarPagina2(page){this.pagination2.currentPage = page;this.listarProceso();},

  },
  mounted() {
    this.listarCompletadas();
    this.listarProceso();
  },
};
</script>
