// All
import NotFound from '../controllers/404.vue';

// Ingreso
import Home from '../controllers/ingreso/HomeController.vue';
import Login from '../controllers/ingreso/LoginController.vue';
import CambiarPassword from '../controllers/ingreso/CambiarPasswordController.vue';

// Base
import Container from '../controllers/ContainerController.vue';
import Dashboard from '../controllers/DashboardController.vue';

// Administracion
import Usuarios from '../controllers/usuarios_roles/UsuariosController.vue';
import Roles from '../controllers/usuarios_roles/RolesController.vue';
import Clientes from '../controllers/usuarios_roles/ClientesController.vue';
import Conductores from '../controllers/usuarios_roles/ConductoresController.vue';

// Información
import Exportar from '../controllers/info/ExportarController.vue';
import Importar from '../controllers/info/ImportarController.vue';

// Reportes
import ReportesServicios from '../controllers/reportes/ResportesServiciosController.vue';
import ReportesTransacciones from '../controllers/reportes/ResportesTransaccionesController.vue';



export default [
    { 
        path: '*',
        component: NotFound
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/app',
        component: Container,
        meta: {
            auth: true,
        },
        children: [
            {
                path: '',
                name: 'start',
                component: Dashboard
            },

            // Usuarios y roles
            {
                path: 'usuarios',
                name: 'usuarios',
                component: Usuarios
            },
            {
                path: 'clientes',
                name: 'clientes',
                component: Clientes
            },
            {
                path: 'conductores',
                name: 'conductores',
                component: Conductores
            },
            {
                path: 'roles',
                name: 'roles',
                component: Roles
            },


            {
                path: 'cambiar-password',
                name: 'cambiar-password',
                component: CambiarPassword
            },

            // Información
            {
                path: 'exportar',
                name: 'exportar',
                component: Exportar
            },
            {
                path: 'importar',
                name: 'importar',
                component: Importar
            },

            // Reportes
            {
                path: 'reportes-servicios',
                name: 'reportes-servicios',
                component: ReportesServicios
            },
            {
                path: 'reportes-transacciones',
                name: 'reportes-transacciones',
                component: ReportesTransacciones
            },
            
        ]
    }
]