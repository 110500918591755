class UrlService {
  base() {
    return apiUrl;
  }
  create(path) {
    return apiUrl + path;
  }
}

export default UrlService;
export const url = new UrlService();
