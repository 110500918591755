<template>
  <section>


    <!-- Modal detalles usuario -->
    <form @submit.prevent="guardar">
      <modal v-model="modalDatos" size="extra">

        <div slot="header" class="modal-header">
          <h4 class="modal-title">Información Usuario</h4>
        </div>

        <div class="modal-body">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <button class="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">
                Información personal</button>
            </li>

            <!-- <li class="nav-item">
              <button class="nav-link" id="acciones-tab" data-bs-toggle="tab" data-bs-target="#acciones" type="button" role="tab" aria-controls="acciones" aria-selected="false">
                Mas Acciones</button>
            </li> -->
          </ul>


          <div class="tab-content" id="myTabContent">

            <!-- Info -->
            <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                <div class="row align-items-center justify-content-center ">
                  <div class="col border-end mt-2 mb-2">
                    <div class="form-group mt-2">
                      <h6 class="form-control-label mb-2" for="usuario-nombre">Nombre</h6>
                      <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuario.firstname">
                    </div>
                    <div class="form-group mt-2">
                      <h6 class="form-control-label mb-2" for="usuario-apellido">Apellido</h6>
                      <input type="text" class="form-control" id="usuario-apellido" placeholder="Apellido" v-model="usuario.lastname">
                    </div>
                    <div class="form-group mt-2">
                      <h6 class="form-control-label mb-2" for="usuario-email">Email</h6>
                      <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuario.email" aria-label="Disabled input example" disabled readonly>
                    </div>
                    <div class="form-group mt-2">
                      <h6 class="form-control-label mb-2" for="usuario-password">Contraseña</h6>
                      <input type="text" class="form-control" id="usuario-password" placeholder="Nueva contraseña" v-model="usuario.password">
                    </div>
                  </div>
                 
                </div>
            </div>



            <!-- Acciones -->
            <!-- <div class="tab-pane fade" id="acciones" role="tabpanel" aria-labelledby="acciones-tab">
              <div class="row d-flex justify-content-center">
                <div class="row mt-4">
                  <div class="col">
                    <h6 v-if="usuario.is_active">Desactivar usuario</h6>
                    <h6 v-else >Activar usuario</h6>
                  </div>
                  <div class="col row d-flex justify-content-center">
                    <button v-if="usuario.is_active" type="button" class="btn btn-danger" @click="modalDatos = false;activarSwitch( usuario )">Desactivar</button>
                    <button v-else type="button" class="btn btn-success" @click="modalDatos = false;activarSwitch(usuario )">Activar</button>
                  </div>
                </div> 
                <div class="row mt-2 mb-2">
                  <div class="col"><h6>Eliminar usuario permanentemente</h6></div>
                  <div class="col row d-flex justify-content-center">
                    <button type="button" class="btn btn-danger" @click="modalDatos = false;eliminar( usuario )">Eliminar</button>
                  </div>
                </div> 
              </div>
            </div> -->

          </div>

        </div>

        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modalDatos = false">Salir</button>
        </div>

      </modal>
    </form>


    <!-- Modal nuevo usuario -->
    <form @submit.prevent="guardar">
      <modal v-model="modalNuevoUsuario" size="extra">

        <div slot="header" class="modal-header">
          <h4 class="modal-title">Nuevo Usuario</h4>
        </div>

        <div class="modal-body">

         <div class="col border-end mt-2 mb-2">
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-nombre">Nombre</h6>
              <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuarioNuevo.firstname">
            </div>
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-apellido">Apellido</h6>
              <input type="text" class="form-control" id="usuario-apellido" placeholder="Apellido" v-model="usuarioNuevo.lastname">
            </div>
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-id">Id( CC )</h6>
              <input type="text" class="form-control" id="usuario-id" placeholder="Identificación" v-model="usuarioNuevo.id_number">
            </div>
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-telefono">Telefono</h6>
              <input type="text" class="form-control" id="usuario-telefono" placeholder="Telefono" v-model="usuarioNuevo.phone_number">
            </div>
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-email">Email</h6>
              <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuarioNuevo.email">
            </div>
            <div class="form-group mt-2">
              <h6 class="form-control-label mb-2" for="usuario-password">Contraseña</h6>
              <input type="text" class="form-control" id="usuario-password" placeholder="Nueva contraseña" v-model="usuarioNuevo.password">
            </div>
          </div>

        </div>

        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Crear nuevo usuario</button>
          <button type="button" class="btn btn-secondary" @click="modalNuevoUsuario = false">Salir</button>
        </div>

      </modal>
    </form>

    <!-- Pagina -->
    <div class="app-card app-card-chart h-100 shadow-sm">
      <div class="app-card-body p-3">
        <div class="d-flex justify-content-between align-items-center">
            <h6> Usuarios</h6>
            <input class="btn btn-primary" type="submit" value="Nuevo Ususario" @click.prevent="agregar()" style="color:white;">
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">
    
        <div class="app-card app-card-chart h-100 shadow-sm">
          <div class="app-card-body p-4">
            <div class="table-responsive">

               <div class="col">
                <div class="row d-flex justify-content-between">

                <div class="col form-group mt-2 mb-4">
                  <h6 class="form-control-label mb-2" for="nombre">Nombre</h6>
                  <input type="text" class="form-control" id="nombre" placeholder="Nombre" v-model="filtroNombre">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="apellido">Apellido</h6>
                  <input type="text" class="form-control" id="apellido" placeholder="Apellido" v-model="filtroApellido">
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="pasajero">Es pasajero</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroPasajero">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="activo">Está activo</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroActivo">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="conductor">Es conductor</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroConductor">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div class="col form-group mt-2 mb-2">
                  <h6 class="form-control-label mb-2" for="verificado">Está verificado</h6>
                  <select class="form-select" aria-label="Default select example" v-model="filtroVerificado">
                    <option selected value="null" >No importa</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                
                <div class="col d-flex align-items-center justify-content-end">
                  <div>
                    <button type="submit" class="btn btn-success" @click.prevent="listar()">Buscar conductores</button>
                  </div>
                </div>

                </div>

              </div>

              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Conductor</th>
                    <th>Pasajero</th>
                    <th>Activo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usuario in listaUsuarios" :key="usuario.id">
                    <td>
                        <a href="" @click.prevent="editar(usuario)" >{{ usuario.firstname +' '+ usuario.lastname }}</a>
                    </td>
                    <!-- <td>{{ usuario.lastname }}</td> -->
                    <td>{{ usuario.email }}</td>
                    <td>
                      <template v-if="usuario.is_driver">
                        <i :class="usuario.is_driver_verified ? 'text-success': 'text-danger'" class="fas fa-check"></i>
                      </template>
                    </td>
                    <td>
                      <template v-if="usuario.is_passenger">
                        <i class="fas fa-check"></i>
                      </template>
                    </td>
                    <td>
                      <template v-if="usuario.is_active">
                        <i class="fas fa-check"></i>
                      </template>
                    </td>
                    <!-- <td>{{ usuario.rol ? usuario.rol.name : 'ND' }}</td> -->
                    <td>
                      <template v-if="usuario.is_active && usuario.is_driver && !usuario.is_driver_verified">
                        <a href="" @click.prevent="validar(usuario)" title="Validar conductor"><span class="badge rounded-pill bg-primary">Validar</span></a>
                      </template>
                      <a href="" @click.prevent="editar(usuario)" title="Editar"><i class="fas fa-edit text-warning"></i></a>
                      <a href="" @click.prevent="eliminar(usuario)" title="Eliminar"><i class="far fa-trash-alt text-danger"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

import { url } from "../../services/url_service";


export default {
  data() {
    return {

      modalDatos: false,
      modalNuevoUsuario: false,


      filtroNombre:null,
      filtroApellido:null,
      filtroPasajero:"null",
      filtroActivo:"null",
      filtroVerificado:"null",
      filtroConductor:"null",


      roles: [],
      listaUsuarios: [],
      usuario: {},
      usuarioNuevo:{},

      id: null,
      pagination: {
        currentPage: 1,
        pages: 1,
      },

    };
  },
  methods: {

    // Crear nuevo usuario
    agregar() {
      this.id = null;
      this.usuarioNuevo={};
      this.modalNuevoUsuario = true;
    },

    // Editar un usuario
    editar(usuario) {
      this.id = usuario.id;
      this.usuario = {
        firstname:usuario.firstname,
        lastname: usuario.lastname,
        email: usuario.email,
        id_name:usuario.id_name,
        is_driver: usuario.is_driver,
      };
      this.modalDatos = true;
    },

    async listar() {
      try {
        let response = await axios.get(url.create("/users"), {
          params: { 
            page: this.pagination.currentPage,
            is_driver_verified: this.filtroVerificado,
            is_passenger: this.filtroPasajero,
            is_active: this.filtroActivo,
            firstname:this.filtroNombre,
            lastname:this.filtroApellido,
            is_driver:this.filtroConductor,
            is_admin:true, 
          },
        });
        this.listaUsuarios = response.data.data;
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        );
      } catch (ex) {
        console.error(ex);
      }
    },

    async eliminar(usuario) {
      let res = confirm(
        `Esta seguro de que desea borrar el usuario ${usuario.email}`
      );
      if (res) {
        try {
          await axios.delete(url.create(`/users/${usuario.id}`));
          alert("Usuario eliminado correctamente");
          await this.listar();
        } catch (ex) {
          alert("Usuario no pudo ser eliminado");
        }
      }
    },

    async validar(usuario) {
      let res = confirm(
        `Esta seguro de que desea validar al usuario ${usuario.email}`
      );
      if (res) {
        try {
          await axios.post(url.create(`/users/${usuario.id}/verify`));
          alert("Usuario validado correctamente");
          await this.listar();
        } catch (ex) {
          alert("Usuario no pudo ser validado");
        }
      }
    },

    async guardar() {
      try {
        let response = this.id
          ? await axios.put(url.create(`/users/${this.id}`), this.usuario)
          : await axios.post(url.create("/users"), this.usuarioNuevo);
        await this.listar();
        this.modalDatos = false;
        this.modalNuevoUsuario = false;
      } catch (ex) {}
    },
    
    async listarRoles() {
      try {
        let response = await axios.get(url.create("/roles"));
        this.roles = response.data;
      } catch (ex) {}
    },

    cambiarPagina(page) {
      this.currentPage = page;
      this.listar();
    },
    async descargarXlsx() {
      let response = await axios.get(url.create("/users/excel"), {
        params: {},
      });
      window.location.href = response.data.enlace;
    },
  },
  mounted() {
    this.listar();
    // this.listarRoles();
  },
};
</script>
