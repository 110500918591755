<template>
  <div class="row g-0 app-auth-wrapper">

    <div class="col-12 auth-main-col text-center p-5">
      <!-- <div class="col-12 col-md-5 col-lg-6 auth-main-col text-center p-5"> -->

      <div class="d-flex flex-column align-content-end">
        <div class="app-auth-body mx-auto">
          <div class="app-auth-branding mb-4"><a class="app-logo" href="#/"><img class="logo-icon mr-2" src="images/logo_dark_60.png" alt="logo"></a></div>
          <h2 class="auth-heading text-center mb-5">Ingreso a Go!</h2>
          <div class="auth-form-container text-left">
            <form class="auth-form login-form" @submit.prevent="login">
              <div class="email mb-3">
                <label class="sr-only" for="signin-email">Email</label>
                <input id="signin-email" name="signin-username" type="email" class="form-control signin-email" placeholder="Email" required="required" v-model="credenciales.email">
              </div>
              

              <div class="password mb-3">
                <label class="sr-only" for="signin-password">Contraseña</label>
                <input id="signin-password" name="signin-password" type="password" class="form-control signin-password" placeholder="Contraseña" required="required" v-model="credenciales.password">
                <div class="extra mt-3 row justify-content-between">
                  <div class="col-6">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="RememberPassword">
                      <label class="form-check-label" for="RememberPassword">
                        Recordarme
                      </label>
                    </div>
                  </div>
                  
                  <div class="col-6">
                    <div class="forgot-password text-right">
                      <a ><router-link :to="{name: 'cambiar-password'}">Olvidaste la contraseña?</router-link> </a>
                    </div>
                  </div>
                  
                </div>
                
              </div>
              
              <div class="text-center">
                <button type="submit" class="btn app-btn-primary btn-block theme-btn mx-auto">Ingresar</button>
              </div>
            </form>

            <!-- <div class="auth-option text-center pt-5">Sin cuenta ? Registrate <a class="text-link" href="signup.html">aqui</a>.</div> -->
          </div>
        </div>
<!--        
        <footer class="app-auth-footer">
          <div class="container text-center py-3">
            <small class="copyright">Designed with <i class="fas fa-heart" style="color: #fb866a;"></i> by <a class="app-link" href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying Riley</a> for developers</small>
          </div>
        </footer>
         -->
      </div>
      
    </div>
  
    <!-- <div class="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
      <div class="auth-background-holder">
      </div>
      <div class="auth-background-mask"></div>
    </div> -->


  </div>

</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      credenciales: {},
    };
  },
  methods: {
    async login() {
      try {
        let response = await this.$auth.authenticate(this.credenciales);
        this.$router.push({ name: "start" });
      } catch (error) {}
    },
  },

  created() {
    this.credenciales = {};
    if (this.$auth.verify()) {
      this.$router.push({ name: "start" });
    }
  },
  
};
</script>
