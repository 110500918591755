<!--
<template>
  <section>
    <nav class="left-sidebar shadow">
      <nav class="navbar navbar-expand navbar-dark bg-dark-blue">
        <span class="navbar-brand d-none d-md-block"><i class="mdi mdi-wifi-tethering mdi-lg"></i> Base app</span>
        <span class="navbar-brand d-md-none"><i class="mdi mdi-wifi-tethering mdi-lg"></i></span>
        <a href="#" class="nav-close left-sidebar-toggler d-md-none ml-auto"><i class="mdi mdi-close mdi-lg"></i></a>
      </nav>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link dropdown-toggle" data-bs-toggle="collapse" href="#collapse-seguridad" role="button" aria-expanded="false" aria-controls="collapse-seguridad">
            <i class="mdi mdi-lock mdi-lg"></i>
            Seguridad
          </a>
          <div class="collapse" id="collapse-seguridad">
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'usuarios' }" v-if="$_.includes($auth.permisos, 'ver_usuarios')">
                  <i class="mdi mdi-people mdi-lg"></i>
                  <span>Usuarios</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'roles' }">
                    <i class="mdi mdi-security mdi-lg"></i>
                    <span>Roles</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'cambiar-password' }">
                    <i class="mdi mdi-verified-user mdi-lg"></i>
                    <span>Cambio de password</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
    <div class="outer-wrapper">
      <nav class="navbar navbar-expand-md navbar-dark bg-blue sticky-top">
        <button class="navbar-toggler left-sidebar-toggler mr-1" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <span class="navbar-brand d-md-none">Base App</span>
        <div class="ml-auto">
          <form class="form-inline my-0">
            <span class="navbar-text">{{ nombre }}</span>
            <button class="btn btn-outline-success ml-1" type="button" @click.prevent="logout"><i class="mdi mdi-exit-to-app mdi-lg"></i></button>
          </form>
        </div>
      </nav>
      <div class="inner-wrapper">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>
-->

<template>
  <div>


    <header class="app-header fixed-top">

      <div class="app-header-inner">
        <div class="container-fluid py-2">
          <div class="app-header-content">
            <div class="row justify-content-between align-items-center">

              <div class="col-auto">
                <a id="sidepanel-toggler" class="sidepanel-toggler d-inline-block d-xl-none" href="#" @click.prevent="sidePanelOpen = true">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img">
                    <title>Menu</title>
                    <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path>
                  </svg>
                </a>
              </div>

              <div class="app-utilities col-auto">
                <!-- <div class="app-utility-item app-notifications-dropdown dropdown">
                  <a class="dropdown-toggle no-toggle-arrow" id="notifications-dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" title="Notifications">
  
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bell icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z" />
                      <path fill-rule="evenodd" d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                    <span class="icon-badge">3</span>
                  </a>
                  

                  <div class="dropdown-menu p-0" aria-labelledby="notifications-dropdown-toggle">
                    <div class="dropdown-menu-header p-3">
                      <h5 class="dropdown-menu-title mb-0">Notifications</h5>
                    </div>
              
                    <div class="dropdown-menu-content">
                      <div class="item p-3">
                        <div class="row gx-2 justify-content-between align-items-center">
                          <div class="col-auto">
                            <img class="profile-image" src="images/man.png" alt="">
                          </div>
                          
                          <div class="col">
                            <div class="info">
                              <div class="desc">Amy shared a file with you. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
                              <div class="meta"> 2 hrs ago</div>
                            </div>
                          </div>
                          
                        </div>
                        
                        <a class="link-mask" href="notifications.html"></a>
                      </div>
                   
                      <div class="item p-3">
                        <div class="row gx-2 justify-content-between align-items-center">
                          <div class="col-auto">
                            <div class="app-icon-holder">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-receipt" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                                <path fill-rule="evenodd" d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                              </svg>
                            </div>
                          </div>
                  
                          <div class="col">
                            <div class="info">
                              <div class="desc">You have a new invoice. Proin venenatis interdum est.</div>
                              <div class="meta"> 1 day ago</div>
                            </div>
                          </div>
                  
                        </div>
                        
                        <a class="link-mask" href="notifications.html"></a>
                      </div>
                      
                      <div class="item p-3">
                        <div class="row gx-2 justify-content-between align-items-center">
                          <div class="col-auto">
                            <div class="app-icon-holder icon-holder-mono">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bar-chart-line" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                              </svg>
                            </div>
                          </div>
                  
                          <div class="col">
                            <div class="info">
                              <div class="desc">Your report is ready. Proin venenatis interdum est.</div>
                              <div class="meta"> 3 days ago</div>
                            </div>
                          </div>
                  
                        </div>
                        
                        <a class="link-mask" href="notifications.html"></a>
                      </div>
                      
                      <div class="item p-3">
                        <div class="row gx-2 justify-content-between align-items-center">
                          <div class="col-auto">
                            <img class="profile-image" src="images/man.png" alt="">
                          </div>
                  
                          <div class="col">
                            <div class="info">
                              <div class="desc">James sent you a new message.</div>
                              <div class="meta"> 7 days ago</div>
                            </div>
                          </div>
                  
                        </div>
                        
                        <a class="link-mask" href="notifications.html"></a>
                      </div>
                    
                    </div>

                    <div class="dropdown-menu-footer p-2 text-center">
                      <a href="notifications.html">View all</a>
                    </div>

                  </div>
                </div> -->
       
                <!-- <div class="app-utility-item">
                  <a href="settings.html" title="Settings">
  
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
                      <path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
                    </svg>
                  </a>
                </div> -->


                <div class="app-utility-item app-user-dropdown dropdown">
                  <a class="dropdown-toggle" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"><img src="images/man.png" alt="user profile"></a>
                  <ul class="dropdown-menu" aria-labelledby="user-dropdown-toggle">
                    <!-- <li><a class="dropdown-item" href="account.html">Cuenta</a></li> -->
                    <!-- <li><a class="dropdown-item" href="settings.html">Settings</a></li> -->
                    <!-- <li>
                      <hr class="dropdown-divider">
                    </li> -->
                    <li><a class="dropdown-item" href="#" @click.prevent="logout">Cerrar Sesión</a></li>
                  </ul>
                </div>
              </div>
              <!--//app-utilities-->
            </div>
            
          </div>
          <!--//app-header-content-->
        </div>
        <!--//container-fluid-->
      </div>


      <div id="app-sidepanel" class="app-sidepanel" :class="sidePanelOpen ? 'sidepanel-visible' : ''">
        <div id="sidepanel-drop" class="sidepanel-drop"></div>
        <div class="sidepanel-inner d-flex flex-column">
          <a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none" @click.prevent="sidePanelOpen = false">&times;</a>
          <div class="app-branding">
            <!-- <a class="app-logo" href="/#/app/"> -->
              <!-- img class="logo-icon mr-2" src="images/logo_dark_24.png" alt="logo"<span class="logo-text">GO!</span> -->
            <!-- </a> -->

            <a class="app-logo" href="/#/app/">
              <img  class="img-fluid"  src="images/logo_dark_24.png" alt="logo">
            </a>

          </div>

          <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
            <ul class="app-menu list-unstyled accordion" id="menu-accordion">
              <li class="nav-item">
                <router-link class="nav-link active" :to="{name:'start'}">
                  <span class="nav-icon">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                      <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                    </svg>
                  </span>
                  <span class="nav-link-text">Inicio</span>
                </router-link>
              </li>

              <!-- <li class="nav-item">
                <a class="nav-link" href="orders.html">
                  <span class="nav-icon">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-card-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                      <path fill-rule="evenodd" d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                      <circle cx="3.5" cy="5.5" r=".5" />
                      <circle cx="3.5" cy="8" r=".5" />
                      <circle cx="3.5" cy="10.5" r=".5" />
                    </svg>
                  </span>
                  <span class="nav-link-text">Servicios</span>
                </a>
              </li> -->


              <!-- <li class="nav-item">
                <a class="nav-link" href="charts.html">
                  <span class="nav-icon">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bar-chart-line" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                    </svg>
                  </span>
                  <span class="nav-link-text">Ajustes</span>
                </a>
              </li> -->

              
              <li class="nav-item has-submenu">
                <a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse" data-bs-target="#submenu-2" aria-expanded="false" aria-controls="submenu-2">
                  <span class="nav-icon">
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge-fill" viewBox="0 0 16 16">
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"/>
                  </svg>
                  </span>
                  <span class="nav-link-text">Usuarios y roles</span>
                  <span class="submenu-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </a>

                <div id="submenu-2" class="collapse submenu submenu-2" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'usuarios'}">Usuarios del sistema</router-link></li>
                  </ul>
                </div>

                <div id="submenu-2" class="collapse submenu submenu-2" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'clientes'}">Clientes</router-link></li>
                  </ul>
                </div>

                <div id="submenu-2" class="collapse submenu submenu-2" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'conductores'}">Conductores</router-link></li>
                  </ul>
                </div>

                <!-- <div id="submenu-2" class="collapse submenu submenu-2" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'roles'}">Roles</router-link></li>
                  </ul>
                </div> -->

              </li>



              <!-- Reportes -->
              <li class="nav-item has-submenu">
                <a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse" data-bs-target="#submenu-1" aria-expanded="false" aria-controls="submenu-1">
                  <span class="nav-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-steps" viewBox="0 0 16 16">
                      <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z"/>
                    </svg>
                  </span>
                  <span class="nav-link-text">Reportes y estadísticas </span>
                  <span class="submenu-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </a>

                <div id="submenu-1" class="collapse submenu submenu-1" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'reportes-servicios'}">Servicios</router-link></li>
                  </ul>
                </div>

                <div id="submenu-1" class="collapse submenu submenu-1" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'reportes-transacciones'}">Transacciones</router-link></li>
                  </ul>
                </div>

              </li>

              <!-- Importaciones y exportaciones -->
              <li class="nav-item has-submenu">
                <a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse" data-bs-target="#submenu-3" aria-expanded="false" aria-controls="submenu-3">
                  <span class="nav-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-back" viewBox="0 0 16 16">
                      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/>
                    </svg>
                  </span>
                  <span class="nav-link-text">Información y archivos</span>
                  <span class="submenu-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </a>

                <div id="submenu-3" class="collapse submenu submenu-3" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'exportar'}">Exportar datos</router-link></li>
                  </ul>
                </div>

                <!-- <div id="submenu-1" class="collapse submenu submenu-1" data-parent="#menu-accordion">
                  <ul class="submenu-list list-unstyled">
                    <li class="submenu-item"><router-link class="submenu-link" :to="{name: 'importar'}">Importar datos</router-link></li>
                  </ul>
                </div> -->

              </li>



              <!-- <li class="nav-item">
                <a class="nav-link" href="help.html">
                  <span class="nav-icon">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg>
                  </span>
                  <span class="nav-link-text">Ayuda</span>
                </a>
              </li> -->

            </ul>
          </nav>


          <!-- <div class="app-sidepanel-footer">
            <nav class="app-nav app-nav-footer">
              <ul class="app-menu footer-menu list-unstyled">

                <li class="nav-item">

                  <a class="nav-link" href="settings.html">
                    <span class="nav-icon">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
                        <path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
                      </svg>
                    </span>
                    <span class="nav-link-text">Ajustes</span>
                  </a>
  
                </li> -->
  
                <!-- <li class="nav-item">
                  <a class="nav-link" href="https://themes.3rdwavemedia.com/bootstrap-templates/admin-dashboard/portal-free-bootstrap-admin-dashboard-template-for-developers/">
                    <span class="nav-icon">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M12 1H4a1 1 0 0 0-1 1v10.755S4 11 8 11s5 1.755 5 1.755V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                        <path fill-rule="evenodd" d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </span>
                    <span class="nav-link-text">License</span>
                  </a>
                </li> -->
  
              <!-- </ul>
            </nav>
          </div> -->
        </div>
      </div>
    </header>

    <div class="app-wrapper">

      <div class="app-content pt-3 p-md-3 p-lg-4">
        <router-view></router-view>
      </div>

      <footer class="app-footer">
        <!-- <div class="container text-center py-3"> -->
          <!-- <small class="copyright">Designed with <i class="fas fa-heart" style="color: #fb866a;"></i> by <a class="app-link" href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying Riley</a> for developers</small> -->
        <!-- </div> -->
      </footer>

    </div>
  </div>
</template>

<script>
import { Collapse, Dropdown } from "bootstrap";
export default {
  data() {
    return {
      sidePanelOpen: false,
    };
  },
  computed: {
    nombre() {
      return `${this.$auth.me.nombre} ${this.$auth.me.apellido}`;
    },
  },
  methods: {
    async logout() {
      await this.$auth.unAuthenticate();
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    var collapseElementList = [].slice.call(
      document.querySelectorAll(".collapse")
    );
    collapseElementList.map(function (el) {
      return new Collapse(el, { toggle: false });
    });
    var dropdownElementList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="dropdown"]')
    );
    dropdownElementList.map(function (el) {
      return new Dropdown(el);
    });
  },
};
</script>
