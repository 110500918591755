<template>
  <div class="container-xl">

    <div class="row justify-content-center mt-3">
      <div class="col-md-12">

        <div class="app-car app-card-chart shadow-sm" v-if="false">
          <div class="app-card-body d-flex justify-content-center align-items-center p-4" style="background-color:white;">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>

        <div class="app-card app-card-chart shadow-sm">
          <div class="app-card-body p-3">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Exportar archivo de historial de servicios</h6>
                <input class="btn btn-primary" type="submit" value="Exportar">
            </div>
          </div>
        </div>

        <!-- <div class="app-card app-card-chart shadow-sm mt-3">
          <div class="app-card-body p-3">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Exportar archivo de historial de transacciones</h6>
                <input class="btn btn-primary" type="submit" value="Exportar">
            </div>
          </div>
        </div> -->


      </div>
    </div>


  </div>
</template>

<script>
export default {
    mounted() {
        console.log('Component mounted.')
    }
}
</script>
