import axios from 'axios';

import { url } from './url_service';

const TOKEN_EXPIRES_KEY = '__token-expires__';
const $storage = window.localStorage;

class AuthService {
  constructor() {
    this._me = null;
    this._filled = false;
  }
  verify() {
    let expires = parseInt($storage.getItem(TOKEN_EXPIRES_KEY) || '0');
    return expires > Date.now();
  }
  async refresh() {
    const response = await axios.post(url.create('/auth/refresh'));
    $storage.setItem(TOKEN_EXPIRES_KEY, (Date.now() + (response.data.expires_in * 60 * 1000)).toString());
    return true;
  }
  async authenticate(credenciales) {
    const response = await axios.post(url.create('/auth/login'), credenciales);
    $storage.setItem(TOKEN_EXPIRES_KEY, (Date.now() + (response.data.expires_in * 60 * 1000)).toString());
    return response.data.token;
  }
  async data() {
    if (!this._filled) {
      this._filled = true;
      this._me = (await axios.get(url.create('/auth/me'))).data;
    }
  }
  get me() {
    return this._me;
  }
  get permisos() {
    return this._me.rol ? this._me.rol.permisos : [];
  }
  async unAuthenticate() {
    $storage.removeItem(TOKEN_EXPIRES_KEY);
    await axios.post(url.create('/auth/logout'));
    this._me = null;
    this._filled = false;
  }
}

export default AuthService;
export const auth = new AuthService();
