<template>
  <div class="fill">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    name: 'Main',
};
</script>

<style>
.fill {
  min-height: 100vh;
  height: 100vh;
}
</style>