<template>
    <section class="container mt-2">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Wellcome to my app</div>

                    <div class="card-body">
                        Home.
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        console.log('Component mounted.')
    }
}
</script>
